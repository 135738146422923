import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable()
export class UtilsService {
  static conformKey(key: string, tenantId: string): string {
    const keyParts = key.split('/');
    if (keyParts.length === 2) {
      return `${tenantId}/${key}`;
    }
    if (keyParts.length === 3) {
      return `${tenantId}/${keyParts[1]}/${keyParts[2]}`;
    }
    if (keyParts.length === 4) {
      return `${tenantId}/${keyParts[1]}/${keyParts[2]}/${keyParts[3]}`;
    }
    return key;
  }

  static convertNumberToISOTimeString(
    n: number | undefined
  ): string | undefined {
    return n
      ? new Date((n ?? 0) * 1000 - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substring(0, 16)
      : undefined;
  }

  static convertISOTimeStringToNumber(s: string): number {
    return s && s !== ''
      ? Math.floor(new Date(s).getTime() / 1000)
      : Date.now() / 1000;
  }

  static clearFormElementError(
    form: FormGroup,
    element: string,
    validator: string
  ) {
    const errors = form.controls[element].errors ?? {};
    delete errors[validator];
    if (Object.keys(errors).length === 0) {
      form.controls[element].setErrors(null);
      return;
    }
    form.controls[element].setErrors({ ...errors });
  }

  /**
   * Copies the object deeply to avoid locked objects
   *
   * @param source any object or array to copy
   */
  static deepCopy(source: any): any {
    return Array.isArray(source)
      ? source.map((item) => UtilsService.deepCopy(item))
      : source instanceof Date
      ? new Date(source.getTime())
      : source && typeof source === 'object'
      ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
          o[prop] = UtilsService.deepCopy(source[prop]);
          return o;
        }, Object.create(Object.getPrototypeOf(source)))
      : source;
  }

  static parseFilter(filter: string): { name: string; value: string } {
    if (filter.startsWith('[')) filter = filter.substring(1);
    if (filter.endsWith(']')) filter = filter.substring(0, filter.length - 1);
    const filterParts = filter.split(' ');
    return { name: filterParts[0], value: filterParts[2].replace(/'/g, '') };
  }

  static flattenNestedArrayOfObjects(arr: any[], field: string): any[] {
    let nested: any[] = [];
    const nestedPropAccess = (obj: any, field: string) =>
      field.split('.').reduce((prev, cur) => prev[cur], obj);

    return arr
      .map((item) => {
        if (
          nestedPropAccess(item, field) &&
          nestedPropAccess(item, field).length
        ) {
          nested = [...nested, ...nestedPropAccess(item, field)];
        }
        return item;
      })
      .concat(
        nested.length ? this.flattenNestedArrayOfObjects(nested, field) : nested
      );
  }

  static mergeById = (a1: any, a2: any) => {
    a1 = a1 || [];
    a2 = a2 || [];
    return a1?.map((itm: any) => ({
      ...a2.find((item: any) => item.id === itm.id && item),
      ...itm,
    }));
  };

  static getUnixTimestamp(date: Date): number {
    if (typeof date.getMonth === 'function') {
      return date.getTime() / 1000;
    }
    return 0;
  }

  static convertArrayToObject(array: any[], key: string) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  }

  static isString(x: any) {
    return Object.prototype.toString.call(x) === '[object String]';
  }

  static isEmpty(val: any): boolean {
    return val == null || !(Object.keys(val) || val).length;
  }

  static convertCurrencyToInt(c: any, fixed = 2): number {
    if (UtilsService.isString(c)) {
      const f = parseFloat(c);
      const fixedValue = f.toFixed(fixed);
      const intString = fixedValue.replace('.', '');
      return parseInt(intString);
    }
    return c;
  }

  static convertIntToCurrency(c?: number, fixed = 2): string {
    if (c === null || c == undefined) return '';
    if (c && c < 0) return '';
    return ((c ?? 0) / 100).toFixed(fixed);
  }

  static convertContentToRenderable(item: string): any {
    try {
      const renderable = JSON.parse(item);
      return renderable;
      // eslint-disable-next-line no-empty
    } catch {}
    return undefined;
  }

  // remove duplicate strings from array
  static removeDuplicatesAndUndefined(
    arr: (string | undefined)[]
  ): (string | undefined)[] {
    if (!arr) return [];
    return arr.filter((item, index) => arr.indexOf(item) === index);
  }

  /**
   * helper for things that should be monitored
   * @param types
   * @param item
   * @returns
   */
  static isApplicableItemToMonitor(types: string[], item: any): boolean {
    let isApplicable = false;
    if (typeof item === 'string') {
      types.forEach((t) => {
        if (typeof t === 'string') {
          if (item.indexOf(t) >= 0) {
            isApplicable = true;
          }
        }
      });
      return isApplicable;
    }
    return types.includes(item?.meta?.objectType);
  }

  // remove all invalid characters for a url
  static removeInvalidUrlCharacters(url: string): string {
    return url.replace(/[^a-zA-Z0-9-_ ]/g, '');
  }

  static slugify(text: string): string {
    if (!text) return '';
    if (text.trim() === '') return '';
    return this.removeInvalidUrlCharacters(text)
      .toLowerCase()
      .replace(/ /g, '-');
  }
}
