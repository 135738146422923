/** Product */

export const loadProductsType = '[catalog/product] load products';
export const loadProductsSuccessType =
  '[catalog/product] load products success';
export const loadProductsFailureType =
  '[catalog/product] load products failure';

export const loadProductsAndSelectProductType =
  '[catalog/product] load products and select product';
export const loadProductsAndSelectProductSuccessType =
  '[catalog/product] load products and select product success';
export const loadProductsAndSelectProductFailureType =
  '[catalog/product] load products and select product failure';

export const selectProductType = '[catalog/product] select product';

export const addProductType = '[catalog/product] add product';
export const addProductSuccessType = '[catalog/product] add product success';
export const addProductFailureType = '[catalog/product] add product failure';

export const updateProductType = '[catalog/product] update product';
export const updateProductSuccessType =
  '[catalog/product] update product success';
export const updateProductFailureType =
  '[catalog/product] update product failure';

export const updateProductIntegrationsType =
  '[catalog/product] update product integrations';
export const updateProductIntegrationsSuccessType =
  '[catalog/product] update product integrations success';
export const updateProductIntegrationsFailureType =
  '[catalog/product] update product integrations failure';

export const deleteProductType = '[catalog/product] delete product';
export const deleteProductSuccessType =
  '[catalog/product] delete product success';
export const deleteProductFailureType =
  '[catalog/product] delete product failure';

/** Coupon */

export const loadCouponsType = '[catalog/coupon] load coupons';
export const loadCouponsSuccessType = '[catalog/coupon] load coupons success';
export const loadCouponsFailureType = '[catalog/coupon] load coupons failure';

export const loadCouponsAndSelectCouponType =
  '[catalog/coupon] load coupons and select coupon';
export const loadCouponsAndSelectCouponSuccessType =
  '[catalog/coupon] load coupons and select coupon success';
export const loadCouponsAndSelectCouponFailureType =
  '[catalog/coupon] load coupons and select coupon failure';

export const selectCouponType = '[catalog/coupon] select coupon';

export const addCatalogCouponType = '[catalog/coupon] add coupon';
export const addCatalogCouponSuccessType =
  '[catalog/coupon] add coupon success';
export const addCatalogCouponFailureType =
  '[catalog/coupon] add coupon failure';

export const updateCouponType = '[catalog/coupon] update coupon';
export const updateCouponSuccessType = '[catalog/coupon] update coupon success';
export const updateCouponFailureType = '[catalog/coupon] update coupon failure';

export const updateCouponIntegrationsType =
  '[catalog/coupon] update coupon integrations';
export const updateCouponIntegrationsSuccessType =
  '[catalog/coupon] update coupon integrations success';
export const updateCouponIntegrationsFailureType =
  '[catalog/coupon] update coupon integrations failure';

export const deleteCouponType = '[catalog/coupon] delete coupon';
export const deleteCouponSuccessType = '[catalog/coupon] delete coupon success';
export const deleteCouponFailureType = '[catalog/coupon] delete coupon failure';

/** PromoCode */

export const loadPromoCodesType = '[catalog/promocode] load promocodes';
export const loadPromoCodesSuccessType =
  '[catalog/promocode] load promocodes success';
export const loadPromoCodesFailureType =
  '[catalog/promocode] load promocodes failure';

export const loadPromoCodesAndSelectPromoCodeType =
  '[catalog/promocode] load promocodes and select promocode';
export const loadPromoCodesAndSelectPromoCodeSuccessType =
  '[catalog/promocode] load promocodes and select promocode success';
export const loadPromoCodesAndSelectPromoCodeFailureType =
  '[catalog/promocode] load promocodes and select promocode failure';

export const selectPromoCodeType = '[catalog/promocode] select promocode';

export const addPromoCodeType = '[catalog/promocode] add promocode';
export const addPromoCodeSuccessType =
  '[catalog/promocode] add promocode success';
export const addPromoCodeFailureType =
  '[catalog/promocode] add promocode failure';

export const updatePromoCodeType = '[catalog/promocode] update promocode';
export const updatePromoCodeSuccessType =
  '[catalog/promocode] update promocode success';
export const updatePromoCodeFailureType =
  '[catalog/promocode] update promocode failure';

export const updatePromoCodeIntegrationsType =
  '[catalog/promocode] update promocode integrations';
export const updatePromoCodeIntegrationsSuccessType =
  '[catalog/promocode] update promocode integrations success';
export const updatePromoCodeIntegrationsFailureType =
  '[catalog/promocode] update promocode integrations failure';

export const deletePromoCodeType = '[catalog/promocode] delete promocode';
export const deletePromoCodeSuccessType =
  '[catalog/promocode] delete promocode success';
export const deletePromoCodeFailureType =
  '[catalog/promocode] delete promocode failure';

/** SKU */

export const loadSKUsType = '[catalog/sku] load skus';
export const loadSKUsSuccessType = '[catalog/sku] load skus success';
export const loadSKUsFailureType = '[catalog/sku] load skus failure';

export const loadSKUsAndSelectSKUType =
  '[catalog/sku] load skus and select sku';
export const loadSKUsAndSelectSKUSuccessType =
  '[catalog/sku] load skus and select sku success';
export const loadSKUsAndSelectSKUFailureType =
  '[catalog/sku] load skus and select sku failure';

export const selectSKUType = '[catalog/sku] select sku';

export const addSKUType = '[catalog/sku] add sku';
export const addSKUSuccessType = '[catalog/sku] add sku success';
export const addSKUFailureType = '[catalog/sku] add sku failure';

export const updateSKUType = '[catalog/sku] update sku';
export const updateSKUSuccessType = '[catalog/sku] update sku success';
export const updateSKUFailureType = '[catalog/sku] update sku failure';

export const updateSKUIntegrationsType =
  '[catalog/sku] update sku integrations';
export const updateSKUIntegrationsSuccessType =
  '[catalog/sku] update sku integrations success';
export const updateSKUIntegrationsFailureType =
  '[catalog/sku] update sku integrations failure';

export const deleteSKUType = '[catalog/sku] delete sku';
export const deleteSKUSuccessType = '[catalog/sku] delete sku success';
export const deleteSKUFailureType = '[catalog/sku] delete sku failure';
