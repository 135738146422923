import { Inject, Injectable } from '@angular/core';
import { AppConfig, APP_CONFIG } from '../models/config';
import { SIDKIK_PROJECT, SIDKIK_TENANT } from '../tokens';
import { UtilsService } from './utils.service';

export enum AssetSize {
  small = 'small',
  medium = 'med',
  large = 'large',
  xlarge = 'xlarge',
  xxlarge = 'xxlarge',
}

const emulatorStorageURL = 'https://local-assets.sidkik.app';
const storageURL = 'https://assets.sidkik.app';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(SIDKIK_PROJECT) private projectId: string,
    @Inject(SIDKIK_TENANT) private tenantId: string
  ) {}

  // this should be a key with tenantId/assets/file
  public getAssetImageURL(key: string, token: string): string {
    let checkedKey = key;
    const keyParts = key.split('/');
    if (keyParts.length === 2) {
      checkedKey = `${this.tenantId}/${key}`;
    }
    const url = this.appConfig.emulator ? emulatorStorageURL : storageURL;
    return `${url}/${this.projectId}/${encodeURIComponent(
      UtilsService.conformKey(key, this.tenantId)
    )}?alt=media&token=${token}`;
  }

  public getProfileImageURL(key: string, qp: string): string {
    const url = this.appConfig.emulator ? emulatorStorageURL : storageURL;
    return `${url}/${this.projectId}/${encodeURIComponent(key)}?${qp}`;
  }
}
