import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import {
  AppConfig,
  APP_CONFIG,
  HTTPTraceHeader,
  TraceService,
  SpanTypes,
} from '@sidkik/global';
import { Observable, catchError, tap, throwError } from 'rxjs';

export interface API {
  resetPassword(email: string, trace?: HTTPTraceHeader): Observable<null>;
}

@Injectable({
  providedIn: 'root',
})
export class APIService implements API {
  constructor(
    @Inject(APP_CONFIG) readonly tenantConfig: AppConfig,
    private readonly http: HttpClient,
    private readonly traceService: TraceService
  ) {}

  private processHeaders(trace?: HTTPTraceHeader): HttpHeaders {
    let headers = new HttpHeaders({
      'ngsw-bypass': 'bypass',
    });

    if (trace) {
      headers = new HttpHeaders({
        Traceparent: trace?.traceparent ?? '',
        Tracestate: trace?.tracestate ?? '',
        'ngsw-bypass': 'bypass',
        'Access-Control-Expose-Headers':
          'Traceparent,TraceState,Orig-Tracestate,Orig-Traceparent,X-Tp', // expose the trace headers in response
      });
    }
    return headers;
  }

  resetPassword(email: string, trace?: HTTPTraceHeader): Observable<null> {
    let internalTrace = false;
    if (!trace) {
      this.traceService.startSpan(SpanTypes.firebaseResetPassword);
      trace = this.traceService.getHTTPHeaderPropagators(
        SpanTypes.firebaseResetPassword
      );
      internalTrace = true;
    }
    return this.http
      .put<null>(
        `${this.tenantConfig.api.endpoint}/admin/authz/reset-password`,
        { email, tenant: this.tenantConfig.firebase.tenantId },
        { headers: this.processHeaders(trace) }
      )
      .pipe(
        catchError((err: any) => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.firebaseResetPassword, err);
          return throwError(() => err);
        }),
        tap(() => {
          internalTrace &&
            this.traceService.endSpan(SpanTypes.firebaseResetPassword);
        })
      );
  }
}
