import {
  Meta,
  Trace,
  Integrations,
  Storage,
  StorageProperties,
  omitUndefinedProps,
  CustomerProperties,
} from '..';
import {
  Cart,
  CartItem,
  Coupon,
  CouponProperties,
  PaymentMethod,
  PaymentMethodProperties,
  PromoCodeProperties,
} from '.';
import { UtilsService, EntityType } from '@sidkik/global';
import { ProductProperties, SKUProperties } from '../catalog';

export interface OrderRequestData {
  customer: CustomerProperties;
  cart: Cart;
  paymentMethod?: PaymentMethod;
  isFree: boolean;
  coupon?: CouponProperties;
  promotionCode?: PromoCodeProperties;
  paymentIntentId?: string;
  paymentIntentSecret?: string;
  subscriptionId?: string;
  affiliateId?: string;
}

export interface OrderRequestProperties extends StorageProperties {
  data: OrderRequestData;
  trace?: Trace;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

// This class deviates from the norm given that the customer directly interfaces
// with the payment provider to enter secure information
export class OrderRequest extends Storage implements OrderRequestProperties {
  public override data!: OrderRequestData;
  public override trace!: Trace;

  constructor(options?: OrderRequestProperties, user?: string) {
    super(options, user, EntityType.OrderRequest);
    this.update(options?.data);
  }

  public update(data?: OrderRequestData): void {
    super.forceSync(); // force resync for monitoring
    this.data = { ...data } as unknown as OrderRequestData;
  }

  public addPaymentMethod(pm: PaymentMethod): void {
    this.data.paymentMethod = pm.toStorage();
  }

  public addTrace(trace?: Trace): void {
    this.trace = { ...trace };
  }

  /**
   * creates a minified version of the order for processing
   * only the required items are stored in the request
   */
  public toMinimalStorage(): any {
    const copyOfMe = UtilsService.deepCopy(super.toStorage());
    for (let i = 0; i < copyOfMe.data.cart.data.items.length; i++) {
      const cartItem = UtilsService.deepCopy(
        copyOfMe.data.cart.data.items[i]
      ) as CartItem;
      cartItem.data.product = this.trimProduct(cartItem.data.product);
      cartItem.data.sku = this.trimSKU(cartItem.data.sku);
      copyOfMe.data.cart.data.items[i] = cartItem;
    }
    return {
      id: copyOfMe.id,
      data: omitUndefinedProps(copyOfMe.data),
      meta: omitUndefinedProps(copyOfMe.meta),
      integrations: omitUndefinedProps(copyOfMe.integrations),
      trace: omitUndefinedProps(copyOfMe.trace),
    };
  }

  private trimProduct(p: ProductProperties): ProductProperties {
    const workingP = UtilsService.deepCopy(p);
    delete workingP.data.active;
    delete workingP.data.attributes;
    delete workingP.data.image;
    delete workingP.data.metadata;
    delete workingP.data.linkedCoursesSections;
    delete workingP.data.unitLabel;
    delete workingP.data.whatIsIncluded;
    delete workingP.data.url;
    delete workingP.data.video;
    delete workingP.data.tags;

    return workingP;
  }

  private trimSKU(s: SKUProperties): SKUProperties {
    const workingS = UtilsService.deepCopy(s);
    delete workingS.data.active;
    delete workingS.data.attributes;
    delete workingS.data.image;
    delete workingS.data.video;
    delete workingS.integrations.state;

    return workingS;
  }
}
