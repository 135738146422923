/** Course */

export const loadMACourseType = '[my-activities/course] load course';
export const loadMACourseSuccessType =
  '[my-activities/course] load course success';
export const loadMACourseFailureType =
  '[my-activities/course] load course failure';
export const selectMACourseType = '[my-activities/course] select course';
export const selectMACourseModuleType =
  '[my-activities/course] select course module';
export const selectMACourseSectionType =
  '[my-activities/course] select course section';
export const selectMACourseAndModuleType =
  '[my-activities/course] select course and module';
export const selectMACourseAndModuleAndSectionType =
  '[my-activities/course] select course and module and section';

export const openMACourseMobileMenuType =
  '[my-activities/course] open mobile menu';
export const closeMACourseMobileMenuType =
  '[my-activities/course] close mobile menu';

/** Flow */

export const loadMAFlowType = '[my-activities/flow] load flow';
export const loadMAFlowSuccessType = '[my-activities/flow] load flow success';
export const loadMAFlowFailureType = '[my-activities/flow] load flow failure';
export const selectMAFlowType = '[my-activities/flow] select flow';
export const selectMAFlowModuleType = '[my-activities/flow] select flow module';
export const selectMAFlowSectionType =
  '[my-activities/flow] select flow section';
export const selectMAFlowAndModuleType =
  '[my-activities/flow] select flow and module';
export const selectMAFlowAndModuleAndSectionType =
  '[my-activities/flow] select flow and module and section';

/** Content */

export const loadMAContentType = '[my-activities/content] load content';
export const loadMAContentSuccessType =
  '[my-activities/content] load content success';
export const loadMAContentFailureType =
  '[my-activities/content] load content failure';
export const selectMAContentType = '[my-activities/content] select content';

/** Course Progress */

export const loadMACourseProgressType =
  '[my-activities/course-progress] load course progress';
export const loadMACourseProgressSuccessType =
  '[my-activities/course-progress] load course progress success';
export const loadMACourseProgressFailureType =
  '[my-activities/course-progress] load course progress failure';
export const selectMACourseProgressType =
  '[my-activities/course-progress] select course progress';
export const addMACourseProgressType =
  '[my-activities/course-progress] add course progress';
export const addMACourseProgressSuccessType =
  '[my-activities/course-progress] add course progress success';
export const addMACourseProgressFailureType =
  'my-activities/course-progress] add course progress failure';

export const updateMACourseProgressType =
  '[my-activities/course-progress] update course progress';
export const updateMACourseProgressSuccessType =
  '[my-activities/course-progress] update course progress success';
export const updateMACourseProgressFailureType =
  '[my-activities/course-progress] update course progress failure';

/** Challenge */

export const loadMAChallengeType = '[my-activities/challenge] load challenge';
export const loadMAChallengeSuccessType =
  '[my-activities/challenge] load challenge success';
export const loadMAChallengeFailureType =
  '[my-activities/challenge] load challenge failure';
export const selectMAChallengeType =
  '[my-activities/challenge] select challenge';
export const selectMAChallengeModuleType =
  '[my-activities/challenge] select challenge module';
export const selectMAChallengeSectionType =
  '[my-activities/challenge] select challenge section';
export const selectMAChallengeAndModuleType =
  '[my-activities/challenge] select challenge and module';
export const selectMAChallengeAndModuleAndSectionType =
  '[my-activities/challenge] select challenge and module and section';

export const openMAChallengeMobileMenuType =
  '[my-activities/challenge] open mobile menu';
export const closeMAChallengeMobileMenuType =
  '[my-activities/challenge] close mobile menu';

/** Challenge Progress */

export const loadMAChallengeProgressType =
  '[my-activities/challenge-progress] load challenge progress';
export const loadMAChallengeProgressSuccessType =
  '[my-activities/challenge-progress] load challenge progress success';
export const loadMAChallengeProgressFailureType =
  '[my-activities/challenge-progress] load challenge progress failure';
export const selectMAChallengeProgressType =
  '[my-activities/challenge-progress] select challenge progress';
export const addMAChallengeProgressType =
  '[my-activities/challenge-progress] add challenge progress';
export const addMAChallengeProgressSuccessType =
  '[my-activities/challenge-progress] add challenge progress success';
export const addMAChallengeProgressFailureType =
  'my-activities/challenge-progress] add challenge progress failure';

export const updateMAChallengeProgressType =
  '[my-activities/challenge-progress] update challenge progress';
export const updateMAChallengeProgressSuccessType =
  '[my-activities/challenge-progress] update challenge progress success';
export const updateMAChallengeProgressFailureType =
  '[my-activities/challenge-progress] update challenge progress failure';
