/** Cart */

export const loadCartType = '[shop/cart] load cart';
export const loadCartSuccessType = '[shop/cart] load cart success';
export const loadCartFailureType = '[shop/cart] load cart failure';

export const loadCartAndSelectCartType =
  '[shop/cart] load cart and select cart';
export const loadCartAndSelectCartSuccessType =
  '[shop/cart] load cart and select cart success';
export const loadCartAndSelectCartFailureType =
  '[shop/cart] load cart and select cart failure';

export const selectCartType = '[shop/cart] select cart';

export const addCartItemType = '[shop/cart] add cart item';
export const addCartItemSuccessType = '[shop/cart] add cart item success';
export const addCartItemFailureType = '[shop/cart] add cart item failure';

export const updateCartType = '[shop/cart] update cart';
export const updateCartSuccessType = '[shop/cart] update cart success';
export const updateCartFailureType = '[shop/cart] update cart failure';

export const updateCartIntegrationsType =
  '[shop/cart] update cart integrations';
export const updateCartIntegrationsSuccessType =
  '[shop/cart] update cart integrations success';
export const updateCartIntegrationsFailureType =
  '[shop/cart] update cart integrations failure';

export const deleteCartItemType = '[shop/cart] delete cart item';
export const deleteCartItemSuccessType = '[shop/cart] delete cart item success';
export const deleteCartItemFailureType = '[shop/cart] delete cart item failure';

export const addCouponType = '[shop/cart] add coupon ';
export const addCouponSuccessType = '[shop/cart] add coupon success';
export const addCouponFailureType = '[shop/cart] add coupon failure';

export const clearCouponType = '[shop/cart] clear coupon ';

/** Request */

export const loadRequestType = '[shop/request] load request';
export const loadRequestSuccessType = '[shop/request] load request success';
export const loadRequestFailureType = '[shop/request] load request failure';

export const addRequestType = '[shop/request] add request ';
export const addRequestSuccessType = '[shop/request] add request success';
export const addRequestFailureType = '[shop/request] add request failure';

export const updateRequestType = '[shop/request] update request';
export const updateRequestSuccessType = '[shop/request] update request success';
export const updateRequestFailureType = '[shop/request] update request failure';

export const updateRequestIntegrationsType =
  '[shop/request] update request integrations';
export const updateRequestIntegrationsSuccessType =
  '[shop/request] update request integrations success';
export const updateRequestIntegrationsFailureType =
  '[shop/request] update request integrations failure';
export const clearTimeoutErrorType = '[shop/request] clear timeout error';
export const clearTimeoutErrorCompletedType =
  '[shop/request] clear timeout error completed';
export const selectRequestType = '[shop/request] select request ';
export const clearSelectedRequestType =
  '[shop/request] clear selected request ';

export const deleteRequestType = '[shop/request] delete request ';
export const deleteRequestSuccessType =
  '[shop/request] delete request  success';
export const deleteRequestFailureType =
  '[shop/request] delete request  failure';

export const attemptPaymentType = '[shop/request] attempt payment ';
export const attemptPaymentSuccessType =
  '[shop/request] attempt payment success';
export const attemptPaymentFailureType =
  '[shop/request] attempt payment failure';
