import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { filter, map, Observable, tap } from 'rxjs';
import { AuthProcessService } from '../../services/auth-process.service';
import { AuthzFacade } from '../../+state/authz.facade';
import { AuthzConfig, AuthzConfigToken } from '@sidkik/global';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate, CanLoad {
  constructor(
    @Inject(AuthzConfigToken) private config: AuthzConfig,
    private authProcessService: AuthProcessService,
    private authFacade: AuthzFacade,
    private router: Router
  ) {}
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authCheck(segments.join('/'));
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authCheck(state.url);
  }

  private authCheck(redirectUrl: string): Observable<boolean> {
    // this
    return this.authFacade.loggedIn$.pipe(
      map((loggedIn) => {
        if (loggedIn) {
          return true;
        } else {
          if (this.config.authGuardFallbackURL) {
            this.router.navigate([`/${this.config.authGuardFallbackURL}`], {
              queryParams: { redirectUrl },
            });
          }
          return false;
        }
      })
    );
    // return this.authProcessService.loaded$.pipe(
    //   filter((loaded) => loaded === true),
    //   tap(() => {
    //     const user = this.authProcessService.user;
    //     if (user) {
    //       if (user.isAnonymous) {
    //         if (this.config.authGuardFallbackURL) {
    //           this.router.navigate([`${this.config.authGuardFallbackURL}`], {
    //             queryParams: { redirectUrl },
    //           });
    //         }
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     } else {
    //       if (this.config.authGuardFallbackURL) {
    //         this.router.navigate([`/${this.config.authGuardFallbackURL}`], {
    //           queryParams: { redirectUrl },
    //         });
    //       }
    //       return false;
    //     }
    //   })
    // );
  }
}
