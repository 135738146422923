<div class="tw-min-h-screen tw-flex tw-flex-col" *ngrxLet="{headerNav:headerNavType$, footerNav:footerNavType$} as nav">
  <nav class="tw-shadow-sm tw-z-[1]" data-testid="header-nav"
    *ngIf="!nav?.headerNav || nav?.headerNav !== navTypes.None">
    <div class="tw-mx-auto tw-max-w-7xl tw-px-4 sm:tw-px-6 lg:tw-px-8">
      <div class="tw-flex tw-h-16 tw-w-full tw-items-center">
        <div class="tw-flex tw-flex-grow tw-items-center">
          <div class="tw-flex-shrink-0 md:tw-hidden">
            <a [routerLink]="['/']" (click)="hideOffCanvasMenu()"><img data-testid="header-nav-logo-condensed"
                class="tw-h-8" [src]="condensedlogoUrl" [alt]="siteName"></a>
          </div>
          <div class="tw-flex-shrink-0 tw-hidden md:tw-block">
            <a [routerLink]="['/']" (click)="hideOffCanvasMenu()"><img data-testid="header-nav-logo"
                class="header-nav-logo tw-h-8" [src]="logoUrl" [alt]="siteName"></a>
          </div>
          <div class="tw-hidden md:tw-block tw-flex-grow tw-w-full ">
            <div class="tw-flex tw-mx-auto tw-w-full tw-items-center tw-justify-center tw-space-x-4"
              *ngIf="!nav?.headerNav || nav.headerNav === navTypes.Full">
              <ng-container *ngFor="let nav of headerNavItems$ | async">
                <a *ngIf="isExternal(nav.url)" [href]="nav.url" target="_blank" rel="noopener noreferrer"
                  class="hover:tw-text-primary-800 tw-text-gray-700 tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                  aria-current="page">{{nav.title}}</a>
                <a *ngIf="!isExternal(nav.url)" [routerLink]="[nav.url]" routerLinkActive="text-primary-700"
                  class="hover:tw-text-primary-800 tw-text-gray-700 tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                  aria-current="page">{{nav.title}}</a>
              </ng-container>
              <a *ngIf="loggedIn$ | async" [routerLink]="['/my-activities']" routerLinkActive="text-primary-700"
                class="hover:tw-text-primary-800 tw-text-gray-700 tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
                aria-current="page">{{myActivitiesName$ | async}}</a>
            </div>
          </div>
        </div>
        <div *ngIf="stripeEnabled" class="tw-hidden md:tw-block">
          <div class="tw-ml-4 tw-flow-root lg:tw-ml-6"
            *ngIf="!nav?.headerNav ||  (nav?.headerNav !== navTypes.None && nav?.headerNav !== navTypes.Minimal)">
            <a [routerLink]="['/shop/cart']" class="tw-group -tw-m-2 tw-flex tw-items-center tw-p-2">
              <div class="tw-flex-col">
                <svg class="tw-mx-auto tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-400 group-hover:tw-text-gray-500"
                  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
                <div class="tw-text-[.6rem] tw-text-gray-400">CART</div>
              </div>
              <span data-testid="header-cart-count"
                class="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-700 group-hover:tw-text-gray-800">{{cartCount$
                |
                async}}</span>
              <span class="tw-sr-only">items in cart, view bag</span>
            </a>
          </div>
        </div>
        <div *ngIf="stripeEnabled" class="tw-hidden md:tw-block">
          <div class="tw-ml-4 tw-flex tw-items-center md:tw-ml-6">
            <a [routerLink]="['/auth/login']" data-testid="header-login-button" *ngIf="(loggedIn$ | async) === false"
              class="tw-text-white tw-bg-primary-500 hover:tw-bg-opacity-75 tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-font-medium">Sign
              In</a>
            <sidkik-user-info *ngIf="loggedIn$ | async"></sidkik-user-info>
          </div>
        </div>
        <div class="-tw-mr-2 tw-flex md:tw-hidden">
          <a *ngIf="stripeEnabled && !nav?.headerNav || (nav?.headerNav !== navTypes.None && nav?.headerNav !== navTypes.Minimal)"
            [routerLink]="['/shop/cart']" class="tw-group -tw-m-2 tw-flex tw-items-center tw-p-2">
            <div class="tw-flex-col">
              <svg class="tw-mx-auto tw-h-6 tw-w-6 tw-flex-shrink-0 tw-text-gray-400 group-hover:tw-text-gray-500"
                fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
              </svg>
              <div class="tw-text-[.6rem] tw-text-gray-400">CART</div>
            </div>
            <span data-testid="header-cart-count-mobile"
              class="tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-700 group-hover:tw-text-gray-800">{{cartCount$
              |
              async}}</span>
            <span class="tw-sr-only">items in cart, view bag</span>
          </a>
          <!-- Mobile menu button -->
          <button data-testid="hamburger-menu" type="button" (click)="toggleOffCanvasMenu()"
            class="tw-inline-flex tw-items-center tw-justify-center tw-ml-4 tw-rounded-md tw-bg-neutral-50 tw-p-2 tw-text-primary-500 hover:tw-bg-neutral-100 hover:tw-bg-opacity-75 hover:tw-text-white focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-white focus:tw-ring-offset-2 focus:tw-ring-offset-primary-600"
            aria-controls="mobile-menu" aria-expanded="false">
            <span class="tw-sr-only">Open main menu</span>
            <svg *ngIf='!isOffCanvasMenu' class="tw-block tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <svg *ngIf='isOffCanvasMenu' class="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <div *ngIf='isOffCanvasMenu' class="md:tw-hidden" id="mobile-menu">
      <div class="tw-space-y-1 tw-px-2 tw-pb-3 tw-pt-2 sm:tw-px-3">
        <ng-container *ngFor="let nav of headerNavItems$ | async">
          <a *ngIf="isExternal(nav.url)" [href]="nav.url" target="_blank" rel="noopener noreferrer"
            (click)="hideOffCanvasMenu()"
            class="tw-text-primary-700 tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
            aria-current="page">{{nav.title}}</a>

          <a *ngIf="!isExternal(nav.url)" (click)="hideOffCanvasMenu()" [routerLink]="[nav.url]"
            routerLinkActive="bg-primary-100"
            class="tw-text-primary-700 tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
            aria-current="page">{{nav.title}}</a>
        </ng-container>
        <a *ngIf="loggedIn$ | async" [routerLink]="['/my-activities']" (click)="hideOffCanvasMenu()"
          routerLinkActive="bg-primary-100"
          class="tw-text-primary-700 tw-block tw-rounded-md tw-px-3 tw-py-2 tw-text-base tw-font-medium"
          aria-current="page">My Activities</a>

      </div>
      <div *ngIf="stripeEnabled" data-testid="header-login-button-mobile"
        class="tw-border-t tw-border-gray-200  tw-pb-3 tw-pt-4">
        <a [routerLink]="['/auth/login']" *ngIf="(loggedIn$ | async) === false"
          class="tw-block  tw-ml-3 tw-px-4 tw-py-2 tw-text-base tw-font-medium tw-text-gray-500 hover:tw-bg-primary-100 hover:tw-text-gray-800">Sign
          In</a>
        <sidkik-user-info [mobileLayout]="true" *ngIf="loggedIn$ | async"></sidkik-user-info>
      </div>
    </div>
  </nav>

  <header class="tw-bg-white tw-shadow-sm">
    <!-- <sidkik-breadcrumb [trail]="breadcrumbTrail$ | async" [includeHome]="false"></sidkik-breadcrumb> -->
  </header>
  <main>
    <!-- <div class="tw-mx-auto tw-py-6 sm:tw-px-6 lg:tw-px-8"> -->
    <router-outlet></router-outlet>
    <!-- </div> -->
  </main>
  <footer data-testid="footer" class="tw-bg-white tw-mt-auto tw-pt-10 tw-w-full" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="tw-sr-only">Footer</h2>
    <div class="tw-mx-auto tw-max-w-7xl tw-px-6 tw-pb-8 tw-pt-16 sm:tw-pt-24 lg:tw-px-8 lg:tw-pt-32"
      *ngIf="!nav.footerNav || nav?.footerNav !== navTypes.None">
      <div class="tw-flex tw-flex-col sm:tw-flex-row">
        <div class="tw-space-y-8">
          <a [routerLink]="['/']" (click)="hideOffCanvasMenu()"><img data-testid="footer-logo" class="tw-h-7"
              [src]="logoUrl" [alt]="siteName"></a>
        </div>
        <div class="tw-w-full tw-flex tw-flex-wrap tw-gap-20 tw-mt-10 sm:tw-mt-0 tw-items-start sm:tw-justify-end">
          <div *ngFor="let group of footerNavItems$ | async">
            <h3 *ngIf="group.group !== emptyGroup" class="tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900">
              {{group.group}}
            </h3>
            <ul role="list" class="tw-space-y-4" [class.tw-mt-6]="group.group !== emptyGroup">
              <li *ngFor="let item of group.items">
                <a *ngIf="isExternal(item.url)" [href]="item.url" target="_blank" rel="noopener noreferrer"
                  class="tw-text-sm tw-leading-6 tw-text-gray-600 hover:tw-text-gray-900">{{ item.title
                  }}</a>
                <a *ngIf="!isExternal(item.url)" [routerLink]="[item.url]"
                  class="tw-text-sm tw-leading-6 tw-text-gray-600 hover:tw-text-gray-900">{{ item.title
                  }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tw-mt-16 tw-flex tw-border-t tw-border-gray-900/10 tw-pt-8 sm:tw-mt-20 lg:tw-mt-24">
        <p class="tw-text-xs tw-leading-5 tw-text-gray-500">&copy; {{now | date:'y'}} {{siteName}}. All rights
          reserved.</p>
        <div class="tw-flex-grow"> </div>
        <p data-testid="footer-version" class="tw-text-xs tw-leading-5 tw-text-gray-300">Powered by: <a
            class="tw-text-gray-400 hover:tw-text-gray-500 tw-underline" href="https://sidkik.com"
            target="_blank">Sidkik</a> <span (click)="enableDebug()"> v{{version}}</span></p>
      </div>
    </div>
  </footer>
</div>