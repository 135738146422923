import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { Product, ProductProperties, SKU, SKUProperties } from '../catalog';
import { Coupon } from './coupon';
import { EntityType } from '@sidkik/global';
import { CartItemProperties } from './cart-item';
import { PromoCode } from './promo-code';

export interface CartData {
  items: CartItemProperties[];
  coupon?: Coupon;
  promoCode?: PromoCode;
}

export interface CartProperties extends StorageProperties {
  data: CartData;
  meta: Meta;
  id: string;
}

export class Cart extends Storage implements CartProperties {
  public override data!: CartData;

  constructor(options?: CartProperties, user?: string) {
    super(options, user, EntityType.Cart);
    this.update(options?.data);
  }

  public update(data?: CartData): void {
    const { items } = data || {};
    this.data.items = items || [];
  }

  // public addItemToCart(item: CartItemProperties) {
  //   const skus = this.data.items.map((item) => item.data.sku.id);
  //   if (skus.indexOf(item.data.sku.id) >= 0) {
  //     throw new Error('Item already in cart');
  //   }
  //   this.data.items = ([] as CartItemProperties[]).concat(this.data.items, [item]);
  // }

  // public removeItemFromCart(item: CartItemProperties) {
  //   const index = this.data.items.findIndex(
  //     (existing) => existing.data.sku.id === item.data.sku.id
  //   );
  //   this.data.items = [
  //     ...this.data.items.slice(0, index),
  //     ...this.data.items.slice(index + 1),
  //   ];
  // }

  // public applyCoupon(coupon: Coupon) {
  //   this.data.coupon = coupon;
  //   this.data.items = [...this.applyDiscountToItems(coupon, this.data.items)];
  // }

  // private applyDiscountToItems(coupon: Coupon, items: CartItemProperties[]): CartItemProperties[] {
  //   let amountOffRemaining = coupon.data.amountOff ?? 0;
  //   return items.map((cartItem) => {
  //     const modCartItem: CartItemProperties = JSON.parse(JSON.stringify(cartItem));
  //     // check if coupon for only a product
  //     if (coupon.data.appliesTo && coupon.data.appliesTo.length > 0) {
  //       if (coupon.data.appliesTo.includes(cartItem.data.product.id)) {
  //         modCartItem.data.discount = coupon.data.percentOff
  //           ? modCartItem.data.sku?.data?.price ?? 0 * (coupon.data.percentOff / 100)
  //           : coupon.data.amountOff;
  //       }
  //     } else {
  //       // apply percent off to each item or if amount off, apply amount off, apply discount until nothing remains
  //       if (coupon.data.percentOff) {
  //         modCartItem.data.discount =
  //           modCartItem.data.sku?.data?.price ?? 0  * (coupon.data.percentOff / 100);
  //       } else {
  //         // if not percent then it is direct amount
  //         if (amountOffRemaining > modCartItem?.data?.sku?.data?.price ?? 0 ) {
  //           modCartItem.data.discount = modCartItem.data.sku?.data?.price ?? 0 ;
  //           amountOffRemaining =
  //             amountOffRemaining - modCartItem.data.sku?.data?.price ?? 0 ;
  //         } else if (amountOffRemaining <= modCartItem.data.sku?.data?.price ?? 0 ) {
  //           modCartItem.data.discount =
  //             modCartItem.data.sku.data.price - amountOffRemaining;
  //           amountOffRemaining = 0;
  //         }
  //       }
  //     }
  //     return modCartItem;
  //   });
  // }

  // private removeDiscountFromItems(items: CartItemProperties[]): CartItemProperties[] {
  //   return items.map((cartItem) => {
  //     const modCartItem: CartItemProperties = JSON.parse(JSON.stringify(cartItem));
  //     delete modCartItem.data.discount;
  //     return modCartItem;
  //   });
  // }

  // public removeCoupon() {
  //   delete this.data.coupon;
  //   this.data.items = this.removeDiscountFromItems(this.data.items);
  // }
}
