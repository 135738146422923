import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ErrorsToStringMap } from '@sidkik/global';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestAll,
  Subscription,
} from 'rxjs';

@Component({
  selector: 'sidkik-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorComponent implements OnInit, OnDestroy {
  errorStrs: string[] = [];
  inputSub!: Subscription;
  errors$: BehaviorSubject<ValidationErrors> = new BehaviorSubject({});
  touched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dirty$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input()
  set errors(value: ValidationErrors | null) {
    this.errors$.next(value ?? {});
  }

  @Input()
  set dirty(value: boolean) {
    this.dirty$.next(value);
  }

  @Input()
  set touched(value: boolean) {
    this.touched$.next(value);
  }

  ngOnInit(): void {
    this.inputSub = combineLatest([
      this.dirty$,
      this.touched$,
      this.errors$,
    ]).subscribe({
      next: ([dirty, touched, errors]) => {
        this.resetErrors();
        if (dirty || touched) {
          if (errors !== null) {
            this.formatErrors(errors);
          }
        }
      },
    });
  }

  ngOnDestroy(): void {
    if (this.inputSub) {
      this.inputSub.unsubscribe();
    }
  }

  resetErrors() {
    this.errorStrs = [];
  }

  formatErrors(errors: ValidationErrors | null) {
    if (errors === null) {
      return;
    }
    Object.entries(errors)
      .filter(([k, v]) => v === true || v !== null || v !== undefined)
      ?.forEach(([k, v]) => {
        let errorStr = ErrorsToStringMap.get(k) ?? `unknown error: ${k}`;
        if (errorStr?.indexOf('__') >= 0) {
          Object.keys(v).forEach((k) => {
            const replaceToken = `__${k}__`;
            errorStr = errorStr.replace(replaceToken, v[k]);
          });
        }
        this.errorStrs.push(errorStr);
      });
  }
}
