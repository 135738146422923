import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { fetch } from '@nx/angular';
import { delay, map, tap, withLatestFrom } from 'rxjs/operators';

import * as AuthzActions from './authz.actions';
import { CustomerProperties, DbService } from '@sidkik/db';
import { EntityType, loadMeMaxRetries } from '@sidkik/global';
import { of } from 'rxjs';
import { AuthzFacade } from './authz.facade';
import { User } from '@angular/fire/auth';

@Injectable()
export class AuthzEffects {
  delay = 2500;

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthzActions.logoutSuccess),
        tap(() => setTimeout(() => this.router.navigate(['/']), 1000))
      ),
    { dispatch: false }
  );

  loadMeFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.loadMeFailure),
      delay(this.delay),
      map((a: any) => AuthzActions.loadMe({ attempt: a.attempt }))
    )
  );

  loadMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthzActions.loadMe),
      withLatestFrom(this.authzFacade.userId$, this.authzFacade.user$),
      fetch({
        id: (
          a: ReturnType<typeof AuthzActions.loadMe>,
          userId: string | null,
          user: Partial<User> | null
        ) => a.type + userId + user?.uid,
        run: (
          a: ReturnType<typeof AuthzActions.loadMe>,
          userId: string | null,
          user: Partial<User> | null
        ) => {
          if (user && user.uid?.startsWith('u_')) {
            return of(null).pipe(map(() => AuthzActions.noop()));
          }
          if (!userId) {
            logger.error(
              'authz:authz.effects.ts:loadMe',
              'userId is not set yet'
            );
            throw new Error('userId is not set yet');
          }
          return this.dbService
            .getEntity<CustomerProperties>(EntityType.Customer, userId)
            .pipe(
              map((doc: CustomerProperties | undefined) => {
                if (!doc || !doc?.integrations?.stripe?.id) {
                  logger.error(
                    'authz:authz.effects.ts:loadMe',
                    'no stripe id yet -- attempt:' +
                      (a.attempt ?? 0) +
                      ' of ' +
                      loadMeMaxRetries
                  );
                  // need stripe id to purchase
                  throw new Error(
                    'user is missing stripe id - attempt:' +
                      (a.attempt ?? 0) +
                      ' of ' +
                      loadMeMaxRetries
                  );
                }
                logger.trace('authz:authz.effects.ts:loadMe', 'got me', doc);
                return AuthzActions.loadMeSuccess({
                  me: doc,
                });
              })
            );
        },
        onError: (a: ReturnType<typeof AuthzActions.loadMe>, error) => {
          if (a.attempt && a.attempt >= loadMeMaxRetries) {
            logger.error(
              'authz:authz.effects.ts:loadMe',
              'unable to load me with max retries'
            );
            return null;
          }
          return AuthzActions.loadMeFailure({
            error: error.message,
            attempt: a.attempt ? a.attempt + 1 : 1,
          });
        },
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private router: Router,
    private readonly dbService: DbService,
    private readonly authzFacade: AuthzFacade
  ) {}
}
