<div [class.tw-flex-col]="!vertical" [class.tw-flex-row]="vertical" class="tw-flex tw-h-full">
  <div class="tabs">
    <div class="tw-block">
      <div class="tw-border-b tw-border-gray-200">
        <nav [class.tw-flex-col]="vertical" [class.tw-space-x-8]="!vertical"
          class="-tw-mb-px tw-flex tw-justify-center sm:tw-justify-start" aria-label="Tabs">
          <a [class.tw-border-transparent]="!tab.active" [class.tw-border-primary-500]="tab.active"
            [class.tw-text-primary-600]="tab.active" (click)="selectTab(tab)" *ngFor="let tab of tabs"
            class="tw-cursor-pointer tw-text-gray-500 hover:tw-text-primary-700 hover:tw-border-primary-300 tw-whitespace-nowrap tw-py-4 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm">{{tab.title}}
            <span *ngIf="tab.infoBubble && tab.infoBubble !== ''"
              class="tw-bg-primary-100 tw-text-primary-600 tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block">{{tab.infoBubble}}</span>
          </a>
        </nav>
      </div>
    </div>
  </div>
  <div class="tab-contents">
    <ng-content></ng-content>
  </div>
</div>