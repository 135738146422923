<div *ngIf="!mobileLayout" class="">
  <div class="tw-group tw-block">
    <button type="button" [sidkikDropdownTrigger]="userinfo"
      class="tw-flex tw-items-center tw-leading-none tw-text-gray-400 hover:tw-tw-text-gray-600 focus:tw-tw-outline-none focus:tw-tw-ring-2 focus:tw-tw-ring-primary-500 focus:tw-tw-ring-offset-2 focus:tw-tw-ring-offset-gray-100"
      id="menu-button" aria-expanded="true" aria-haspopup="true">
      <span class="tw-sr-only">Open options</span>
      <sidkik-avatar [imageUrl]="userPhoto ?? ''"></sidkik-avatar>
    </button>
    <sidkik-dropdown #userinfo>
      <sidkik-dropdown-item>
        <div class="tw-group tw-block tw-flex-1">
          <div class="tw-flex tw-items-center">
            <div>
              <sidkik-avatar [imageUrl]="userPhoto ?? ''"></sidkik-avatar>
            </div>
            <div class="tw-ml-3">
              <p class="tw-text-sm tw-font-medium tw-text-gray-700 group-hover:tw-tw-text-gray-900">{{userName}}
              </p>
              <p class="tw-text-xs tw-font-medium tw-text-gray-500 group-hover:tw-tw-text-gray-700">{{userEmail}}
              </p>
            </div>
          </div>
        </div>
      </sidkik-dropdown-item>
      <sidkik-dropdown-item [routerLink]="['/my-account/profile/details']">My Profile</sidkik-dropdown-item>
      <sidkik-dropdown-item [routerLink]="['/my-account/orders/list']">Orders</sidkik-dropdown-item>

      <sidkik-dropdown-item [dividerTop]="true" [routerLink]="['/auth/logout']">Sign
        out</sidkik-dropdown-item>
    </sidkik-dropdown>
  </div>
</div>
<div *ngIf="mobileLayout" class="tw-px-3 tw-py-2  tw-pb-3 tw-pt-4">
  <div class="tw-flex tw-items-center tw-ml-3">
    <div>
      <sidkik-avatar [imageUrl]="userPhoto ?? ''"></sidkik-avatar>
    </div>
    <div class="tw-ml-3">
      <p class="tw-text-sm tw-font-medium tw-text-gray-700 group-hover:tw-tw-text-gray-900">{{userName}}</p>
      <p class="tw-text-xs tw-font-medium tw-text-gray-500 group-hover:tw-tw-text-gray-700">{{userEmail}}</p>
    </div>
  </div>
  <div class="tw-mt-3 tw-space-y-1">
    <a [routerLink]="['/my-account/profile/details']"
      class="tw-block tw-px-4 tw-py-2 tw-text-base tw-font-medium tw-text-gray-500 hover:tw-tw-bg-gray-100 hover:tw-tw-text-gray-800">My
      Account</a>
    <a [routerLink]="['/my-account/orders/list']"
      class="tw-block tw-px-4 tw-py-2 tw-text-base tw-font-medium tw-text-gray-500 hover:tw-tw-bg-gray-100 hover:tw-tw-text-gray-800">Orders</a>
    <a [routerLink]="['/auth/logout']"
      class="tw-block tw-px-4 tw-py-2 tw-text-base tw-font-medium tw-text-gray-500 hover:tw-tw-bg-gray-100 hover:tw-tw-text-gray-800">Sign
      out</a>
  </div>
</div>