import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { ProductProperties, SKUProperties } from '../catalog';
import { EntityType } from '@sidkik/global';

export interface CartItemData {
  product: ProductProperties;
  sku: SKUProperties;
  quantity?: number;
  discount?: number;
  discountAmount?: number;
}

export interface CartItemProperties extends StorageProperties {
  data: CartItemData;
  meta: Meta;
  id: string;
}

export class CartItem extends Storage implements CartItemProperties {
  public override data!: CartItemData;

  constructor(options?: CartItemProperties, user?: string) {
    super(options, user, EntityType.CartItem);
    if (options?.data) this.update(options.data);
  }

  public update(data: CartItemData): void {
    this.data = data;
  }
}
