import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IconRegistry } from './services/icon-registry.service';

@NgModule({
  imports: [HttpClientModule],
})
export class IconRegistryModule {
  constructor(
    private iconRegistry: IconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/ui/icons.3.svg')
    );
  }
}
