import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, debounceTime, Subscription } from 'rxjs';
import { ComboBoxPanelDefaultOptions } from './combo-box-panel';
import { ComboBoxPositionX, ComboBoxPositionY } from './combo-box-positions';
import { ComboBoxTriggerDirective } from './combo-box-trigger.directive';

export const defaultOptions: ComboBoxPanelDefaultOptions = {
  overlapTrigger: false,
  xPosition: 'after',
  yPosition: 'below',
  backdropClass: 'cdk-overlay-transparent-backdrop',
  hasBackdrop: false,
};

export interface ComboBoxOption {
  value: any;
  label: string;
  description?: string;
  filtered?: boolean;
}

export enum ComboType {
  button = 'button',
  filtered = 'filtered',
  chicklets = 'chicklets',
  freeform = 'freeform',
  filteredChicklets = 'filteredChicklets',
  buttonPlus = 'buttonPlus',
  lookup = 'lookup',
}

@Component({
  selector: 'sidkik-combo-box',
  templateUrl: './combo-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ComboBoxComponent,
    },
  ],
})
export class ComboBoxComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
  @Input() overlapTrigger = defaultOptions.overlapTrigger;
  @Input() xPosition: ComboBoxPositionX = defaultOptions.xPosition;
  @Input() yPosition: ComboBoxPositionY = defaultOptions.yPosition;
  @Input() backdropClass = defaultOptions.backdropClass;
  @Input() hasBackdrop = defaultOptions.hasBackdrop;
  @Input() defaultPlaceholder = 'Please select...';
  @Input()
  set options(val: ComboBoxOption[] | null | undefined) {
    this._options = val ?? [];
    this.filteredOptions$.next(val ?? []);
  }

  get options(): ComboBoxOption[] | null | undefined {
    return this._options;
  }

  @Input() multiSelect = false;
  @Input() comboType = ComboType.button;

  @Input() nonFormValues: any[] | undefined = [];
  @Input() prefixFlagForItemsNeedingCreation: string | undefined = undefined;

  @Output() closed = new EventEmitter<void>();
  @Output() itemAdded = new EventEmitter<{
    item: any;
    shouldCreate: boolean;
  }>();
  @Output() itemRemoved = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<any>();

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @ViewChild('filter') filterField!: ElementRef;

  @ViewChild(ComboBoxTriggerDirective) trigger!: ComboBoxTriggerDirective;

  filteredOptions$: BehaviorSubject<ComboBoxOption[]> = new BehaviorSubject<
    ComboBoxOption[]
  >([]);
  inputChange$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  valueChange$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  openingSub!: Subscription;
  closingSub!: Subscription;

  private _options: ComboBoxOption[] = [];
  value: any;
  onChange: any;
  onTouch: any;
  filterVisible = false;
  panelOpen = false;
  comboTypes = ComboType;
  panelRef!: ComboBoxTriggerDirective;
  separatorKeysCodes: string[] = ['Enter'];
  readyToDetectAddRemove = false;
  currentFilter = '';

  constructor(private cdr: ChangeDetectorRef) {
    this.inputChange$
      .pipe(debounceTime(200))
      .subscribe((f) => this.filterValues(f));
    // force multiselect
    if (this.comboType === ComboType.freeform) {
      this.multiSelect = true;
    }
    // force single select
    if (this.comboType === ComboType.filtered) {
      this.multiSelect = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['nonFormValues'] && changes['nonFormValues'].currentValue) {
      this.writeValue(changes['nonFormValues'].currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.closingSub) this.closingSub.unsubscribe();
    if (this.openingSub) this.openingSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.closingSub = this.trigger.closing.subscribe(
      () => (this.panelOpen = false)
    );
    this.openingSub = this.trigger.opening.subscribe(() => {
      this.panelOpen = true;
      if (
        this.filterField?.nativeElement &&
        this.filterField.nativeElement.value === ''
      ) {
        this.filterValues('');
      }
    });
  }

  writeValue(obj: any): void {
    // if is multi select determine if added value or removed value
    if (this.multiSelect && this.readyToDetectAddRemove) {
      if (obj && Array.isArray(obj)) {
        const currentValue = this.value ?? [];
        if (obj.length > currentValue.length) {
          const addedValue = obj.filter((item) => !currentValue.includes(item));
          let shouldCreate = false;
          if (
            this.prefixFlagForItemsNeedingCreation !== undefined &&
            !addedValue
              .toString()
              .startsWith(this.prefixFlagForItemsNeedingCreation)
          ) {
            shouldCreate = true;
          }
          if (addedValue.length > 0) {
            this.itemAdded.emit({ item: addedValue[0], shouldCreate });
          }
        }
      }
    }

    // clone incoming immutable objects
    if (Array.isArray(obj)) {
      this.value = [...obj];
    } else {
      this.value = obj;
    }
    this.valueChanged.emit(this.value);
    this.valueChange$.next(this.value);
    this.readyToDetectAddRemove = true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setDisabledState?(isDisabled: boolean): void {}

  removeValue(v: any) {
    if (Array.isArray(this.value)) {
      this.itemRemoved.emit(v);
      this.value = this.value.filter((value) => value !== v);
      this.changed([...this.value]);
    }
  }

  filterBlurred() {
    if (this.filterField?.nativeElement) {
      this.filterField.nativeElement.value = '';
      this.currentFilter = '';
    }
  }

  checkKey(evt: KeyboardEvent) {
    if (this.separatorKeysCodes.includes(evt.code)) {
      this.filterRequest(''); // clear filter
      const fieldValue = this.filterField.nativeElement.value;
      if (this.filterField?.nativeElement) {
        this.filterField.nativeElement.value = '';
      }
      this.trigger.toggleComboBox();
      // check to make sure not already a value in the lookup
      if (this.options?.find((o) => o.value === fieldValue)) {
        return;
      }
      // add value to list
      this._options.push({
        label: fieldValue,
        value: fieldValue,
        filtered: false,
      });
      setTimeout(() => {
        this.value.push(fieldValue);
        this.changed(this.value);
        if (
          this.prefixFlagForItemsNeedingCreation !== undefined &&
          !fieldValue.startsWith(this.prefixFlagForItemsNeedingCreation)
        ) {
          this.itemAdded.emit({ item: fieldValue, shouldCreate: true });
        } else {
          this.itemAdded.emit({ item: fieldValue, shouldCreate: false });
        }
      });
    }
  }

  inputFilterRequest(evt: any): void {
    const filter = (evt?.target as any).value ?? '';
    this.currentFilter = filter;
    this.filterRequest(filter);
  }

  filterRequest(val: string) {
    val = val ?? '';
    if (this.trigger && this.trigger.isComboBoxOpen === false) {
      this.trigger.openComboBox();
    }
    this.inputChange$.next(val);
  }

  filterValues(filter: string): void {
    let availableCount = 0;
    const filtered =
      this.options?.map((o) => {
        if (o.label?.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
          availableCount++;
          return { ...o, filtered: false };
        } else {
          return { ...o, filtered: true };
        }
      }) ?? [];
    if (availableCount === 0) {
      filtered.push({
        filtered: false,
        label: 'No values match...',
        value: 'NO_VALUE_MATCH',
      });
    }

    this.filteredOptions$.next(filtered);
  }

  toggleFilter() {
    this.filterVisible = !this.filterVisible;
    this.filterValues('');
  }

  changed(val: any[]) {
    this.cdr.detectChanges();
    // add to allow non form based usage
    this['onTouch']?.();
    if (this.filterField) {
      this.filterField.nativeElement.value = '';
    }
    this.filterVisible = false;
    this.writeValue(val);
    if (this.multiSelect) {
      this['onChange']?.(val);
    } else {
      this['onChange']?.(val[0]);
    }
  }
}
