import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { Integrations } from '../integrations';
import { EntityType } from '@sidkik/global';

export interface PaymentMethodData {
  name: string;
  expMonth: string;
  expYear: string;
  country: string;
  last4: string;
  brand: string;
  fingerprint: string;
  cvcCheck: string;
}

export interface PaymentMethodProperties extends StorageProperties {
  data: PaymentMethodData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

// This class deviates from the norm given that the customer directly interfaces
// with the payment provider to enter secure information
export class PaymentMethod extends Storage implements PaymentMethodProperties {
  public override data!: PaymentMethodData;

  constructor(options?: PaymentMethodProperties, user?: string) {
    super(options, user, EntityType.PaymentMethod);
    this.update(options?.data);
  }

  public update(data?: PaymentMethodData): void {
    this.data = { ...data } as unknown as PaymentMethodData;
  }

  public setIntegrations(integrations?: Integrations): void {
    this.integrations = { ...integrations } as unknown as Integrations;
  }
}
